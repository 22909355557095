import { AlertModal, Button, Loader, ProductView } from 'components'
import { useContext } from 'react'
import { MedBotsContext } from '../context'
import { useNavigate, useParams } from 'react-router-dom'
import { PageHeader } from './PageHeader'
import { useMutation, useQueryClient } from 'react-query'
import { UPDATE_PRODUCT_ON_MEDBOT } from 'apiv2/calls/admin'
import { useModal } from 'context/Modal/ModalContext'
import { useRemoveProduct } from './View/useRemoveProduct'

export const ProductDetails = () => {
  const queryClient = useQueryClient()
  const modal = useModal()
  const nav = useNavigate()
  const { productId } = useParams()
  const { actions, lineItems, medbotId } = useContext(MedBotsContext)

  const { mutate } = useMutation(UPDATE_PRODUCT_ON_MEDBOT, {
    onMutate() {
      modal.actions.open(<Loader />)
    },
    onSuccess() {
      modal.actions.close()
      queryClient.invalidateQueries(['get_medbot_details'])
      nav(-1)
    },
    onError() {
      modal.actions.open(<AlertModal message="There was an error updating product details." />)
    },
  })

  const { removeProduct } = useRemoveProduct()

  return (
    <>
      <PageHeader title={'View Product'} onBack={() => nav(-1)} />
      <ProductView
        allowQtyControls
        isInOrder
        onDelete={product =>
          modal.actions.open(
            <AlertModal
              message={`Are you sure you want to remove ${product.productName} from this MedBot?`}
              confirmText={'Remove'}
              onConfirm={() => {
                if (medbotId) {
                  removeProduct({ medbotId, lineItemId: product?.lineItemId })
                } else {
                  actions.deleteProduct(product.productId)
                }
              }}
              denyText={'Keep'}
              onDeny={modal.actions.close}
            />
          )
        }
        onUpdate={product => {
          if (medbotId) {
            mutate({
              medbotId,
              product: {
                lineItemId: product?.lineItemId,
                quantity: product?.quantity,
                variantId: product?.variantId || product?.variants?.[0].variantId,
                doctorsMessage: product?.doctorsMessage,
                refillFrequency: product?.refillFrequency,
              },
            })
          } else {
            actions.updateProduct(product)
          }
        }}
        product={lineItems.find(item => +item.productId === +productId)}
      />
      <Button style={{ marginTop: '20px', width: '150px' }} onClick={() => nav(-1)}>
        Done
      </Button>
    </>
  )
}
