import { ShipButton } from '.'
import { Product } from '..'
import { NoOrders } from '../NoOrders'
import './ViewOrder.scss'
import { Spinner } from 'pages/RecommendationPortal/Spinner'
import { useCallback } from 'react'
import { useProductControls } from '../Product/useProductControls'
import { PatientListWrap } from 'components'
import { PoweredByMedBotDarkBlue } from 'assets/vector'

const ViewOrder = ({ error = false, isLoading = false, data, lineItems }) => {
  const productControls = useProductControls()

  const forProducts = useCallback(
    product => ({
      order: data,
      doctorId: data?.doctorId,
      product,
      productControls,
    }),
    [data, productControls]
  )

  const listProducts = useCallback(
    () => lineItems?.map?.((product, i) => <Product {...forProducts(product)} key={i} />),
    [lineItems, forProducts]
  )

  if (error) return <div className="view-order-error">There was an error</div>
  if (isLoading) return <Spinner />
  if (!lineItems?.length) return <NoOrders />

  return (
    <div className="view-order-wrap">
      <PatientListWrap title={'Recommendation for Purchase'}> {listProducts()}</PatientListWrap>
      <PoweredByMedBotDarkBlue style={{ alignSelf: 'center' }} />
      <ShipButton data={data} />
    </div>
  )
}

export default ViewOrder
