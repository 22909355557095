import { Button } from 'components'
import { CancelButton } from 'pages/RecommendationPortal/Orders'
// import { StripePayButton } from '../Stripe'
import './FormButtons.scss'
import { useLocation } from 'react-router-dom'
import { LOCATION_STATE_MODES } from 'utilities/Patient/constants'

/*
should be added as prop if going to use <StripePayButton />
-----> showStripePay = false
*/
export const FormButtons = ({ disabled, onSubmit = undefined, type = 'submit', text = 'Continue' }) => {
  const { state } = useLocation()

  return (
    <div className="payment-form__actions">
      <Button onClick={onSubmit} disabled={disabled} className="checkout-button" type={type}>
        {text}
      </Button>
      {state?.mode !== LOCATION_STATE_MODES.CONVENIENCE_STORE && <CancelButton />}
    </div>
  )
}
