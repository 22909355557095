import { useContext, useMemo } from 'react'
import {
  BasketIcon,
  OrdersIcon,
  PlusIcon,
  PracticeIcon,
  WeeklyPaymentIcon,
  CreateOrderIcon,
  MedbotOrderIcon,
  SingleOrderIcon,
  WhiteThumbsUpBotSmall,
  BlockPatientIcon,
  ReportsMenuIcon,
  HospitalBuildingIcon,
} from '../../../../assets/vector'
import { providerTypes, userRoles } from 'utilities/constants'
import { MainContext } from 'context'
import { usePracticeFeatureSettings } from 'utilities'

const unprotected = new Set([...Object.keys(userRoles), ...Object.keys(providerTypes)])
const doctorStaffOnly = new Set(['DOCTOR', 'STAFF'])
const staffTier2Exempt = new Set([...[...unprotected].filter(s => s !== userRoles.STAFFTIER2.toUpperCase())])

const PracticeMenu = () => {
  const { admin } = useContext(MainContext)
  /*TEST HERE TOO */
  const { data } = usePracticeFeatureSettings(admin?.user?.practiceId)

  const showWellnessStore = useMemo(
    () =>
      data?.data?.storeEnabled === true
        ? [
            {
              label: 'Send Wellness Store',
              icon: <HospitalBuildingIcon />,
              name: 'send-wellness-store',
              permission: unprotected,
            },
          ]
        : [],
    [data?.data?.storeEnabled]
  )

  const menu = useMemo(
    () => [
      {
        label: 'Analytics',
        icon: <WeeklyPaymentIcon />,
        name: '',
        end: true,
        permission: doctorStaffOnly,
      },
      {
        label: 'Send Recommendation',
        permission: unprotected,
        icon: <CreateOrderIcon />,
        name: 'create-order',
        path: 'create-order/medbot',
        subMenu: [
          { icon: <MedbotOrderIcon />, name: 'medbot', label: 'Saved Recommendations' },
          { icon: <SingleOrderIcon />, name: 'single', label: 'Create New Recommendation' },
        ],
      },
      ...showWellnessStore,
      {
        label: 'MedBots',
        icon: <WhiteThumbsUpBotSmall className="thumbs-up-bot" />,
        name: 'medbots',
        permission: staffTier2Exempt,
      },
      {
        label: 'All Orders',
        icon: <OrdersIcon />,
        name: 'orders',
        path: 'orders/all',
        permission: staffTier2Exempt,
        subMenu: [
          {
            icon: <PlusIcon />,
            name: 'open',
            label: 'Shipped Orders',
          },
        ],
      },
      { label: 'Practice Details', permission: staffTier2Exempt, icon: <PracticeIcon />, name: 'practice' },

      { label: 'Products', icon: <BasketIcon />, name: 'products', permission: staffTier2Exempt },
      {
        label: 'Blocked Patients',
        icon: <BlockPatientIcon />,
        name: 'patients',
        permission: staffTier2Exempt,
      },
      { label: 'Reports', icon: <ReportsMenuIcon />, name: 'reports', permission: staffTier2Exempt },
    ],
    [showWellnessStore]
  )

  return menu
}
export default PracticeMenu
