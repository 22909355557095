import { useContext } from 'react'
import { MedBotsContext } from '../../context'
import './View.scss'
import { Button, Loader, ProductsInOrder } from 'components'
import { useNavigate } from 'react-router-dom'
import { useMedbotDetails } from './useMedbotDetails'
import { EditName } from './EditName'
import { PageHeader } from '../PageHeader'
import { RedButton } from './RedButton'
import { AutomateButton } from './AutomateButton/AutomateButton'
import { useModal } from 'context/Modal/ModalContext'
import { useManageMedbot } from './useManageMedbot'
import { ConfirmButton } from './ConfirmButton'

export const View = ({ edit, create }) => {
  const nav = useNavigate()
  const modal = useModal()
  const { medBotName, lineItems } = useContext(MedBotsContext)
  const { isLoading, isFetching } = useMedbotDetails()

  const { handleSubmit, isLoading: manageLoading } = useManageMedbot()

  if (isLoading || isFetching || manageLoading || (isFetching && !lineItems.length)) return <Loading isLoading />

  return (
    <>
      <PageHeader title={edit ? 'View MedBot' : 'Review Your MedBot'} onBack={edit ? () => nav(-1) : null} />
      <div className="medbots-view">
        <div className="medbots-view__name">
          <p className="medbots-view__name--label">MedBot Name</p>
          <div className="medbots-view__name--main">
            <h4>{medBotName}</h4>
            <Button
              onClick={() =>
                modal.actions.open(
                  <EditName currentName={medBotName} onUpdate={({ medBotName }) => handleSubmit(medBotName)} />
                )
              }
            >
              Edit MedBot Name
            </Button>
          </div>
        </div>
        <ProductsInOrder
          useEditButton
          label="MedBot Products"
          onSelect={product => nav(`/admin/medbots/product/${product.productId}`)}
          onAddMore={() => nav('/admin/medbots/addproducts')}
          products={lineItems}
          provider={null}
        />
        {/* {create && !admin?.user?.role?.includes('admin') ? (
          <>
            <PromoMessage provider={admin.user} />
            <CreatedBy label="MedBot Created By" /> 
          </>
        ) : null} */}

        <div className="medbots-view__action-buttons">
          {create && <ConfirmButton />}
          <RedButton edit={edit} />
          <AutomateButton />
          <Button onClick={() => nav(-1)}>Done</Button>
        </div>
      </div>
    </>
  )
}

export const Loading = ({ isLoading }) =>
  isLoading ? (
    <div className="loader-wrap">
      <Loader fadeEdge={false} />
    </div>
  ) : null
